<template>
	<div class="cart_page">
		<header class="header">
			<div class="content">
				<span></span>
				<span class="title">购物车</span>
				<span class="edit" v-if="list.length" @click="onEdit">{{isEdit?'完成':'编辑'}}</span>
			</div>
		</header>
		
		<div class="list"  v-if="list.length">
			<div class="item">
				<view class="item_head">
					<div>
						<img @click="onNormalAll" :src="normalAll ? sel_act_icon:sel_icon" class="sel_icon" alt="">
					</div>
					<span class="title">门店热卖</span>
					<span class="flag flag1">专区满{{config.minPrice}}起送</span>
				</view>
				<div class="goods">
					<div class="g_item" v-for="item, index in normal" :key="index">
						<div>
							<img @click="onNormalSingle(index)" :src="item.isChecked == 1 ?sel_act_icon:sel_icon" class="sel_icon" alt="">
						</div>
						<img :src="item.picUrl" class="g_img" alt="">
						<div class="info">
							<div class="title">{{item.goodsName}}</div>
							<div class="desc">{{item.specifications.join()}}</div>
							<div class="btm">
								<span class="price">￥{{item.price}}</span>
								<van-stepper @change="onStepChange($event, item)" :min="item.minNum" :max="item.stockNum" v-model="item.number" button-size="20"/>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="item">
				<view class="item_head">
					<div>
						<img @click="onGroupAll" :src="groupAll ? sel_act_icon:sel_icon" class="sel_icon" alt="">
					</div>
					<span class="title">团购商品</span>
					<span class="flag flag2">团购商品</span>
				</view>
				<div class="goods">
					<div class="g_item" v-for="item, index in group" :key="index">
						<div>
							<img @click="onGroupSingle(index)" :src="item.isChecked == 1 ? sel_act_icon : sel_icon" class="sel_icon" alt="">
						</div>
						<img :src="item.picUrl" class="g_img" alt="">
						<div class="info">
							<div class="title">{{item.goodsName}}</div>
							<div class="desc">{{item.specifications.join()}}</div>
							<div class="btm">
								<span class="price">￥{{item.price}}</span>
								<van-stepper @change="onStepChange($event, item)" :min="item.minNum" :max="item.stockNum" v-model="item.number" button-size="20"/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="no_cart" v-else>
			<img src="../assets/cn.png" class="img" alt="">
			<p>空空如也～</p>
			<button class="button" @click="goHome">去逛逛</button>
		</div>
		<div class="kong"></div>
		<footer class="cart_footer" v-if="list.length">
			<div class="qs" v-if="spreadPrice > 0">
				<span>再买{{spreadPrice}}起送</span>
				<span @click="goHome">去选购></span>
			</div>
			<div class="total_wrap">
				<div class="all_sel" @click="onSelAll">
					<img :src="selAll ? sel_act_icon:sel_icon" class="sel_icon" alt="">
					<span class="all_sel_txt">全选</span>
				</div>
				<div class="total" v-show="!isEdit">
					<span class="total_txt">合计：</span>
					<span class="total_pri">￥{{totalPrice}}</span>
				</div>
				<button v-show="!isEdit" class="button" @click="goOrder">结算({{totalCount}})</button>
				<button class="button del" v-show="isEdit" @click="delCart">删除</button>
			</div>
		</footer>
		<nav-bar></nav-bar>
	</div>
</template>

<script>
	import {toRefs, onMounted, computed, reactive, onActivated} from 'vue'
	import {useRouter} from 'vue-router'
	import {getCart, deleteCartItem, updateIschecked, updateNumber, countPrice, checkout} from '../service/cart.js'
	import navBar from '@/components/NavBar'
	import {
		setLocal,
		getLocal
	} from '@/common/js/utils'
	import {
		Toast
	} from 'vant'
	export default {
		components: {
		  navBar,
		},
		setup() {
			const router = useRouter();
			const state = reactive({
				sel_icon: require('@/assets/sel.png'),
				sel_act_icon: require('@/assets/sel_act.png'),
				list: [],
				group: [],
				normal: [],
				checkList: [],
				selAll: false,
				normalAll: false,
				groupAll: false,
				spreadPrice: 0,
				totalPrice: 0,
				totalPri: 0,
				totalCount: 0,
				isEdit: false,
				config: {}
			})
			onMounted( () => {
			})
			onActivated(() => {
				state.config = JSON.parse(getLocal('config'));
				initData();
				onCountPrice();
				onTotalPrice();
			})
			const initData = async () => {
				let res = await getCart();
				state.list = [...res.data.group, ...res.data.normal]
				state.group = res.data.group;
				state.normal = res.data.normal;
				state.groupAll = res.data.group.findIndex(item=>{return item.isChecked == 0}) == -1?true:false;
				state.normalAll = res.data.normal.findIndex(item=>{return item.isChecked == 0}) == -1?true:false
				if(state.groupAll && state.normalAll){
					state.selAll = true;
				}
			}
			const goHome = () => {
				router.push({
					path: '/'
				})
			}
			const goOrder = () => {
				let normal = state.checkList.filter(item=>{return item.goodsTypeId == 1});
				let group = state.checkList.filter(item=>{return item.goodsTypeId == 2});
				if(normal.length == 0 && group.length == 0) return Toast.fail(`请先勾选`);
				
				if(normal.length == 0 && group.length) {
					return router.push({
						path: '/create-order'
					})
				}
				if(normal.length && group.length == 0) {
					if( state.config.minPrice > state.totalPri)  return Toast.fail(`再买${state.spreadPrice}起送`);
					return router.push({
						path: '/create-order'
					})
				}
				if(normal.length && group.length) {
					if( state.config.minPrice > state.totalPri) return Toast.fail(`再买${state.spreadPrice}起送`);
					return router.push({
						path: '/create-order'
					})
				}
			}
			const onStepChange = (num, item) => {
				if(num == 0 || num == '') return Toast.fail('数量不能为空');
				if(num < item.minNum) return Toast.fail('不能小于最小订购量'+item.minNum)
				onUpdateNumber({
					id: item.id,
					number: num
				})
			}
			//总价
			const onTotalPrice = async () => {
				state.totalPri = 0;
				let res = await checkout();
				state.totalPrice = res.data.totalPrice;
				state.totalCount = res.data.goodsList.length;
				state.checkList = res.data.goodsList;
				res.data.goodsList.filter(item=>{return item.goodsTypeId == 1}).forEach(i => {
					state.totalPri += i.number * i.price;
				})
			}
			//差价
			const onCountPrice = async () => {
				let res = await countPrice();
				state.spreadPrice = res.data.lackMoney;
			}
			//数量变化
			const onUpdateNumber = async (data) => {
				let res = await updateNumber(data);
				onCountPrice();
				onTotalPrice();
			}
			//删除商品
			const delCart = async() => {
				state.list.filter(item=>{return item.isChecked == 1}).forEach(async i=>{
					await deleteCartItem(i.id);
					initData();
				})
			}
			//勾选商品
			const onUpdateIschecked = async (data) => {
				let res = await updateIschecked(data);
				state.totalPri = 0;
				onCountPrice();
				onTotalPrice();
			}
			//门店热卖全选
			const onNormalAll = () => {
				if(state.normalAll) {
					state.normal = state.normal.map(item=>{
						item.isChecked = 0;
						onUpdateIschecked({
							id: item.id,
							isChecked: 0
						})
						return item;
					})
				} else {
					state.normal = state.normal.map(item=>{
						item.isChecked = 1;
						onUpdateIschecked({
							id: item.id,
							isChecked: 1
						})
						return item;
					})
				}
				state.normalAll = !state.normalAll;
				let group_i = state.group.findIndex(item=>{
					return item.isChecked == 0
				})
				let normal_i = state.normal.findIndex(item=>{
					return item.isChecked == 0
				})
				if(group_i == -1 && normal_i == -1) {
					state.selAll = true;
				} else {
					state.selAll = false;
				}
			}
			//门店单选
			const onNormalSingle = (index) => {
				let flag = state.normal[index].isChecked;
				if(flag == 0) {
					state.normal[index].isChecked = 1;
					onUpdateIschecked({
						id: state.normal[index].id,
						isChecked: 1
					})
				} else {
					state.normal[index].isChecked = 0;
					onUpdateIschecked({
						id: state.normal[index].id,
						isChecked: 0
					})
				}
				let group_i = state.group.findIndex(item=>{
					return item.isChecked == 0
				})
				let normal_i = state.normal.findIndex(item=>{
					return item.isChecked == 0
				})
				if(normal_i == -1) {
					state.normalAll = true;
					if(group_i == -1 && normal_i == -1){
						state.selAll = true;
					}
				} else {
					state.normalAll = false;
					state.selAll = false;
				}
			}
			// 团购商品全选
			const onGroupAll = () => {
				if(state.groupAll) {
					state.group = state.group.map(item=>{
						item.isChecked = 0;
						onUpdateIschecked({
							id: item.id,
							isChecked: 0
						})
						return item;
					})
				} else {
					state.group = state.group.map(item=>{
						item.isChecked = 1;
						onUpdateIschecked({
							id: item.id,
							isChecked: 1
						})
						return item;
					})
				}
				state.groupAll = !state.groupAll;
				let group_i = state.group.findIndex(item=>{
					return item.isChecked == 0
				})
				let normal_i = state.normal.findIndex(item=>{
					return item.isChecked == 0
				})
				if(group_i == -1 && normal_i == -1) {
					state.selAll = true;
				} else {
					state.selAll = false;
				}
			}
			//团购单选
			const onGroupSingle = (index) => {
				let flag = state.group[index].isChecked;
				if(flag == 0) {
					state.group[index].isChecked = 1;
					onUpdateIschecked({
						id: state.group[index].id,
						isChecked: 1
					})
				} else {
					state.group[index].isChecked = 0;
					onUpdateIschecked({
						id: state.group[index].id,
						isChecked: 0
					})
				}
				let group_i = state.group.findIndex(item=>{
					return item.isChecked == 0
				})
				let normal_i = state.normal.findIndex(item=>{
					return item.isChecked == 0
				})
				if(group_i == -1) {
					state.groupAll = true;
					if(group_i == -1 && normal_i == -1){
						state.selAll = true;
					}
				} else {
					state.groupAll = false;
					state.selAll = false;
				}
			}
			//全选
			const onSelAll = () => {
				if(state.selAll) {
					state.group = state.group.map(item=>{
						item.isChecked = 0;
						onUpdateIschecked({
							id: item.id,
							isChecked: 0
						})
						return item;
					})
					state.normal = state.normal.map(item=>{
						item.isChecked = 0;
						onUpdateIschecked({
							id: item.id,
							isChecked: 0
						})
						return item;
					})
					state.groupAll = false;
					state.normalAll = false;
				} else {
					state.group = state.group.map(item=>{
						item.isChecked = 1;
						onUpdateIschecked({
							id: item.id,
							isChecked: 1
						})
						return item;
					})
					state.normal = state.normal.map(item=>{
						item.isChecked = 1;
						onUpdateIschecked({
							id: item.id,
							isChecked: 1
						})
						return item;
					})
					state.groupAll = true;
					state.normalAll = true;
				}
				state.selAll = !state.selAll;
			}
			//编辑事件
			const onEdit = () => {
				state.isEdit = !state.isEdit;
			}
			return {
				...toRefs(state),
				goHome,
				goOrder,
				onSelAll,
				onGroupAll,
				onNormalAll,
				onNormalSingle,
				onGroupSingle,
				onStepChange,
				onEdit,
				delCart
			}
		}
	}
</script>

<style scoped lang="less">
	.cart_page{
		width: 100vw;
		height: 100vh;
		background-color: #f5f5f5;
		.sel_icon{
			width: 20px;
			height: 20px;
		}
		.header{
			width: 100%;
			height: 44px;
			background-color: #fff;
			position: fixed;
			top: 0;
			left: 0;
			z-index: 999;
			.content{
				width: 100%;
				height: 44px;
				padding: 0 16px;
				box-sizing: border-box;
				display: flex;
				align-items: center;
				justify-content: space-between;
				position: relative;
			}
			.title{
				font-size: 18px;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}
			.edit{
				font-size: 18px;
				color: #999;
			}
		}
		.list{
			padding-top: 60px;
			// padding-bottom: 140px;
			.item{
				width: 100%;
				background-color: #fff;
				box-sizing: border-box;
				padding: 16px;
				margin-bottom: 10px;
				.item_head{
					display: flex;
					align-items: center;
					.title{
						font-size: 18px;
						color: #333;
						margin-left: 10px;
					}
					.flag {
						padding: 2px 4px;
						border-radius: 4px;
						font-size: 12px;
						margin-left: 10px;
					}
					.flag1{
						background-color: rgba(255, 0, 0, .2);
						color: rgba(255, 0, 0, 1);
					}
					.flag2{
						background-color: rgba(255, 111, 0, .2);
						color: rgba(255,111,0, 1);
					}
				}
				.goods{
					margin-top: 20px;
					.g_item{
						display: flex;
						align-items: center;
						margin-top: 10px;
						.g_img{
							width: 74px;
							height: 74px;
							margin: 0 10px;
						}
						.info{
							width: calc(100% - 100px);
						}
						.title{
							font-size: 14px;
							color: #333;
							text-overflow: -o-ellipsis-lastline;
							overflow: hidden;
							text-overflow: ellipsis;
							display: -webkit-box;
							-webkit-line-clamp: 2;
							line-clamp: 2;
							-webkit-box-orient: vertical;
						}
						.desc{
							display: inline-block;
							height: 23px;
							padding: 0 4px;
							text-align: center;
							line-height: 23px;
							background: #f1f1f1;
							border-radius: 2px;
							margin: 5px 0 10px 0;
							color: #999;
							font-size: 12px;
						}
						.btm{
							width: 100%;
							display: flex;
							align-items: center;
							justify-content: space-between;
						}
						.price{
							font-size: 14px;
							color: #FF0004;
						}
					}
				}
			}
		}
		.no_cart{
			width: 100%;
			text-align: center;
			position: absolute;
			top: 20%;
			left: 50%;
			transform: translateX(-50%);
			img{
				width: 259px;
				height: 140px;
			}
			p{
				font-size: 15px;
				color: #999;
				margin-top: 15px;
			}
			.button{
				width: 130px;
			}
		}
		.kong{
			width: 100%;
			height: 150px;
			background-color: #f7f7f7;
		}
		.cart_footer{
			width: 100%;
			position: fixed;
			bottom: 50px;
			left: 0;
			z-index: 999;
			.qs{
				width: 100%;
				height: 32px;
				background-color: #FF0004;
				box-sizing: border-box;
				padding: 0 10px;
				color: #fff;
				font-size: 14px;
				display: flex;
				align-items: center;
				justify-content: space-between;
			}
			.total_wrap{
				width: 100%;
				height: 50px;
				background-color: #fff;
				display: flex;
				align-items: center;
				justify-content: space-between;
				box-sizing: border-box;
				padding: 0 16px;
				.all_sel{
					display: flex;
					align-items: center;
					.all_sel_txt{
						font-size: 13px;
						color: #666;
						margin-left: 5px;
					}
				}
				.total{
					display: flex;
					align-items: center;
					.total_txt{
						font-size: 14px;
						color: #333;
					}
					.total_pri{
						font-size: 15px;
						color: #FF0000;
					}
				}
				.button{
					width: 97px;
				}
			}
		}
		.button{
			height: 40px;
			line-height: 40px;
			background: linear-gradient(180deg,#e9192e, #fc5d3e);
			border-radius: 20px;
			color: #ffffff;
			border: none;
			font-size: 16px;
		}
	}
</style>